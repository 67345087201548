.button-template .p-button i {
  line-height: 2.25rem;
}
.button-template .p-button.google {
  background: linear-gradient(
    to left,
    var(--purple-600) 50%,
    var(--purple-700) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}
.button-template .p-button.google:hover {
  background-position: left bottom;
}
.button-template .p-button.google i {
  background-color: var(--purple-700);
}
.button-template .p-button.google:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
.button-template .p-button.youtube {
  background: linear-gradient(
    to left,
    var(--pink-600) 50%,
    var(--pink-700) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--pink-700);
}
.button-template .p-button.youtube:hover {
  background-position: left bottom;
}
.button-template .p-button.youtube i {
  background-color: var(--pink-700);
}
.button-template .p-button.youtube:focus {
  box-shadow: 0 0 0 1px var(--pink-400);
}
.button-template .p-button.vimeo {
  background: linear-gradient(
    to left,
    var(--green-200) 50%,
    var(--green-300) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #000;
  border-color: var(--green-300);
}
.button-template .p-button.vimeo:hover {
  background-position: left bottom;
}
.button-template .p-button.vimeo i {
  background-color: var(--green-300);
}
.button-template .p-button.vimeo:focus {
  box-shadow: 0 0 0 1px var(--green-400);
}
.button-template .p-button.facebook {
  background: linear-gradient(
    to left,
    var(--indigo-600) 50%,
    var(--indigo-700) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--indigo-700);
}
.button-template .p-button.facebook:hover {
  background-position: left bottom;
}
.button-template .p-button.facebook i {
  background-color: var(--indigo-700);
}
.button-template .p-button.facebook:focus {
  box-shadow: 0 0 0 1px var(--indigo-400);
}
.button-template .p-button.twitter {
  background: linear-gradient(
    to left,
    var(--blue-400) 50%,
    var(--blue-500) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--blue-500);
}
.button-template .p-button.twitter:hover {
  background-position: left bottom;
}
.button-template .p-button.twitter i {
  background-color: var(--blue-500);
}
.button-template .p-button.twitter:focus {
  box-shadow: 0 0 0 1px var(--blue-200);
}
.button-template .p-button.slack {
  background: linear-gradient(
    to left,
    var(--orange-400) 50%,
    var(--orange-500) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--orange-500);
}
.button-template .p-button.slack:hover {
  background-position: left bottom;
}
.button-template .p-button.slack i {
  background-color: var(--orange-500);
}
.button-template .p-button.slack:focus {
  box-shadow: 0 0 0 1px var(--orange-200);
}
.button-template .p-button.amazon {
  background: linear-gradient(
    to left,
    var(--yellow-400) 50%,
    var(--yellow-500) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #000;
  border-color: var(--yellow-500);
}
.button-template .p-button.amazon:hover {
  background-position: left bottom;
}
.button-template .p-button.amazon i {
  background-color: var(--yellow-500);
}
.button-template .p-button.amazon:focus {
  box-shadow: 0 0 0 1px var(--yellow-200);
}
.button-template .p-button.discord {
  background: linear-gradient(
    to left,
    var(--bluegray-700) 50%,
    var(--bluegray-800) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--bluegray-800);
}
.button-template .p-button.discord:hover {
  background-position: left bottom;
}
.button-template .p-button.discord i {
  background-color: var(--bluegray-800);
}
.button-template .p-button.discord:focus {
  box-shadow: 0 0 0 1px var(--bluegray-500);
}
.btn-h-95 {
  height: 95%;
}
.btn-mr-1 {
  margin-right: 1rem;
}
.btn-ml-auto {
  margin-left: auto;
}
.btn-w-full {
  width: 100%;
}

@media screen and (max-width: 960px) {
  .button-demo .p-button {
    margin-bottom: 0.5rem;
  }
  .button-demo .p-button:not(.p-button-icon-only) {
    display: flex;
    width: 100%;
  }
  .button-demo .p-buttonset .p-button {
    margin-bottom: 0;
  }
}

.btn-primary {
  background-color: #227b96;
  border-color: #227b96;
}

.btn-primary:hover {
  background-color: #3095b2;
  border-color: #3095b2;
}

.btn-danger {
  background-color: #ea0633;
  border-color: #ea0633;
}

.btn-danger:hover {
  background-color: #f91f4a;
  border-color: #f91f4a;
}

.btn-success {
  background-color: #006644;
  border-color: #006644;
}

.btn-success:hover {
  background-color: #05855b;
  border-color: #05855b;
}

.btn-sync-status {
  border-radius: 25px;
  padding: 0.5rem;
  border: none;
  color: #39afd1;
  background-color: transparent;
}

.btn-sync-status:hover {
  background-color: transparent;
}

.btn-icon {
  border-radius: 25px;
  padding: 0.5rem;
  border: none;
  color: #39afd1;
  background-color: transparent;
}

.btn-icon:active {
  background: transparent !important;
  color: #39afd1 !important;
  border-color: none;
  box-shadow: none;
}

.btn-icon:focus {
  box-shadow: none;
}

.icon-smaller{
  width: 1rem;
  height: 1rem;
}

body[data-layout-color=dark] {
  .btn-outline-danger {
    color: #FBCBD5;
    border: 1px solid #FBCBD5;
  }
}

body[data-layout-color=light] {
  .btn-outline-danger {
    color: #A10727;
    border: 1px solid #A10727;
  }
}




