.nav-pills .nav-link.active {
  background-color: #227B96;
}

.nav-link {
  color: black;
}

body[data-layout-color='dark'] {
  .nav-link {
    color: #aab8c5;
  }
}