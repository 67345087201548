.content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 7;
    /* Above the content but below the left menu */
    display: flex;
    justify-content: center;
    align-items: center;
}

