//
// right-sidebar.scss
//

/* rtl:begin:options: {
  "autoRename": true,
  "stringMap":[]
} */

.end-bar {
  background-color: $rightbar-bg;
  box-shadow: 0 0 24px 0 rgba($dark, 0.1), 0 1px 0 0 rgba($dark, 0.08);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: $rightbar-width;
  z-index: 9999;
  float: right !important;
  right: -($rightbar-width + 10px);
  top: 0;
  bottom: 0;
  padding-bottom: 80px;

  .rightbar-title {
    background-color: $rightbar-title-bg;
    padding: 27px 25px;
    color: $rightbar-title-color;
  }
  .end-bar-toggle {
    background-color: $rightbar-title-btn-bg;
    height: 24px;
    width: 24px;
    line-height: 22px;
    color: $rightbar-title-btn-color;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;
  }
}

.rightbar-overlay {
  background-color: $rightbar-overlay-bg;
  opacity: 0.2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}

.end-bar-enabled {
  .end-bar {
    right: 0;
  }
  .rightbar-overlay {
    display: block;
  }
}

@include media-breakpoint-down(md) {
  .end-bar {
    overflow: auto;
  }
}
