//
// rangeslider.scss
//

.noUi-target {
  background: #{map-get($grays, '100')} !important;
  border-radius: 5px !important;
  border: $border-width solid #{map-get($grays, '200')} !important;
  box-shadow: none !important;
}

.noUi-horizontal {
  height: 10px !important;

  .noUi-handle {
    width: 24px !important;
    height: 20px !important;
    right: -12px !important;
    top: -6px !important;
    outline: none !important;
  }
}

.noUi-handle {
  border: $border-width solid #{map-get($grays, '300')} !important;
  background: $white !important;
  box-shadow: none !important;
}

.noUi-handle:before,
.noUi-handle:after {
  height: 10px !important;
  width: 2px !important;
  background: #{map-get($grays, '400')} !important;
  left: 9px !important;
  top: 4px !important;
}

.noUi-handle:after {
  left: 12px !important;
}

.noUi-connect {
  background: $primary !important;
}

body[data-layout-color='dark'] {
  .noUi-target {
    background: #{map-get($dark-grays, '100')} !important;
    border: $border-width solid #{map-get($dark-grays, '200')} !important;
  }
  .noUi-handle {
    border: $border-width solid #{map-get($dark-grays, '300')} !important;
  }
  .noUi-handle:before,
  .noUi-handle:after {
    background: #{map-get($dark-grays, '400')} !important;
  }
}
