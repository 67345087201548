.edit-btn {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 6rem;
}

.details-org {
  .detail-value {
    word-break: break-word;
  }
}
